<template>
  <div class="options_container">
    <div class="options_top">
      <div class="ai_tabs">
        <el-tooltip effect="dark" :content="!isUnfold?'打开AI工具箱':'收起AI工具箱'" placement="right-end">
          <img class="unfold_icon hand" v-if="isUnfold" @click="fold"
               src="https://img.infox-med.com/webImg/infoxmedV2/icons/expandIcon.png" alt="">
          <img class="fold_icon hand" v-if="!isUnfold" @click="fold"
               src="https://img.infox-med.com/webImg/infoxmedV2/icons/menuFold.png" alt="">
          <!--  -->

        </el-tooltip>
        <div class="tab_item hand flc" v-for="item in aiTabList" @click="tabChange(item)"
             :class="{'activate':selectTab === item.val}">
          <span>{{item.label}}</span>
        </div>
      </div>
    </div>

    <div class="reply_content" :class="{p20: selectTab!=='116-1'}">
      <aiReplyVue v-show="selectTab === '1151'" ref="1151" fId="1151" @finish="setMindMapContent" />

      <MindMap ref="mindMapRef" v-show="selectTab === '1151-1'" :content="mindMapContent" />

      <pdfAiChat v-show="selectTab === '113'" :docInfo="docInfo" />

      <aiReplyVue v-show="selectTab === '114'" ref="114" fId="114" />

      <CreatedPpt v-show="selectTab === '116-1'" ref="116-1" :docId="docInfo.id"
                  :docInfo="docInfo" />

    </div>

  </div>
</template>

<script>
import aiReplyVue from '@/components/aiSendMsg/aiReply.vue'
import MindMap from './components/mindMap.vue'
import pdfAiChat from './components/pdfChat.vue'
import CreatedPpt from './components/createdPpt.vue'

export default {
  props: {
    docInfo: Object
  },
  components: {
    aiReplyVue,
    MindMap,
    pdfAiChat,
    CreatedPpt
  },
  data () {
    return {
      aiTabList: [
        {
          label: '对话PDF',
          val: '113'
        }, {
          label: '文献速读',
          val: '1151',
          isReply: true
        }, {
          label: '思维导图',
          val: '1151-1'
        }, {
          label: 'AI解读',
          val: '114',
          isReply: true
        },
        {
          label: '生成PPT',
          val: '116-1'
        }
      ],
      selectTab: '',
      isUnfold: true,
      mindMapContent: ''
    }
  },
  methods: {
    setMindMapContent (content) {
      this.mindMapContent = content
      if (this.selectTab === '1151-1') {
        this.$refs.mindMapRef.renderMarkmap()
      }
    },
    fold () {
      this.isUnfold = !this.isUnfold
      this.$emit('setAiOptionsState', this.isUnfold)
    },
    tabChange (item) {
      if (this.selectTab === item.val) return
      // if (this.$store.getters.aiLinkLoading) return this.$message.warning('AI消息回复中， 请稍后')
      this.selectTab = item.val
      if (item.isReply) {
        item.isReply = false
        this.$refs[this.selectTab].submitSend({
          messageType: 2,
          attachment: this.docInfo.id
        })
      }
      if (item.val === '1151-1') {
        if (!this.mindMapContent) {
          this.$refs['1151'].submitSend({
            messageType: 2,
            attachment: this.docInfo.id
          })
        } else {
          this.$refs.mindMapRef.renderMarkmap()
        }
      }

      if (item.val === '116-1') {
        this.$refs['116-1'].createdPpt()
      }

    },
    initSelectTab () {
      const tab = this.$route.query.tab
      let defaultTab = this.aiTabList[0]
      if (tab) {
        const find = this.aiTabList.find(item => item.val === tab)
        if (find) defaultTab = find
      }
      this.tabChange(defaultTab)

    }
  },
  mounted () {
    this.initSelectTab()
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.options_container {
  height: 100vh;
  width: 440px;
}
.options_top {
  height: 33px;
  border: 1px solid #dddedf;
}
.ai_tabs {
  display: flex;
  align-items: center;
  height: 100%;
  .unfold_icon {
    display: inline-block;
    width: 34px;
    padding: 0 8px;
  }
  .fold_icon {
    position: fixed;
    right: 20px;
    width: 18px;
  }
  .tab_item {
    padding: 0 16px;
    height: 100%;
    &:hover {
      background-color: rgba(217, 218, 219, 0.6);
    }
  }
  .activate {
    background-color: #d9dadb;
    &:hover {
      background-color: #d9dadb;
    }
  }
}
.reply_content {
  height: calc(100vh - 30px);
}
.p20 {
  padding: 20px 0;
}
</style>
