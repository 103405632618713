<template>
  <div class="page_container">
    <div class="page">
      <div class="page_content mdContent">
        <div v-html="info"></div>
      </div>
      <div v-show="false" id="downloadFile_box" ref="pageContentRef" v-html="info"></div>
    </div>
  </div>
</template>

<script>
import system from '@/api/system.js'
import { downloadFileToUrl } from '@/utils/index.js'

export default {
  props: {
    info: {
      type: String,
      default: ''
    },
    docInfo: Object,
    exportLoading: Boolean
  },
  components: {},
  data () {
    return {

    }
  },
  methods: {
    async imageUrlToBase64 (url) {
      try {
        const response = await fetch(url, { mode: "cors" });
        const blob = await response.blob();

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("图片转换 Base64 失败:", error);
        return null;
      }
    },
    async export () {
      this.$emit('update:exportLoading', true)
      const el = this.$refs.pageContentRef
      // const html = `
      // '<div class="test-simhei"> <h2>SimHei 字体测试</h2> <p style="font-family:简宋;">这是一段 SimHei 字体的中文文本：</p> <ul>     <li style="font-family:简宋;">测试内容 1：你好，世界！</li>     <li>测试内容 2：1234567890</li>     <li>测试内容 3：！@#￥%……&amp;*（）</li> </ul>  </div>      <div class="test-default"> <h2>默认字体对比</h2> <p>这是一段未指定字体的文本：</p> <ul>     <li>测试内容 1：你好，世界！</li>     <li>测试内容 2：1234567890</li>     <li>测试内容 3：！@#￥%……&amp;*（）</li>      </ul>  </div>'    
      // ` 
      const html =  el.innerHTML
      const res = await system.proxy({
        url: 'http://172.20.60.11:5004/convert_html_to_pdf',
        body: {
          html
        }
      },
        {
          hideLoading: true
        }
      )
      await downloadFileToUrl({
        url: res.url,
        fileName: this.docInfo.docTitleZh + '.pdf'
      })

      this.$emit('update:exportLoading', false)
    }

  },
  mounted () {
    const imgs = document.querySelectorAll('#downloadFile_box img')
    imgs.forEach(async item => {
      item.src = await this.imageUrlToBase64(item.src)
    })
    const ps = document.querySelectorAll('#downloadFile_box p')
    ps.forEach(item => {
      item.style.lineHeight = '1.8em'
      item.style.fontSize = '28px'
      item.style.fontFamily = '简宋'

    })
    const h1s = document.querySelectorAll('#downloadFile_box h1')
    h1s.forEach(item => {
      item.style.lineHeight = '1.8em'
      item.style.fontSize = '36px'
      item.style.fontWeight = '600'
       item.style.fontFamily = '简宋'
    })
    const h2s = document.querySelectorAll('#downloadFile_box h2')
    h1s.forEach(item => {
      item.style.lineHeight = '1.8em'
      item.style.fontSize = '32px'
      item.style.fontWeight = '600'
       item.style.fontFamily = '简宋'
    })
    const h3s = document.querySelectorAll('#downloadFile_box h3')
    h3s.forEach(item => {
      item.style.lineHeight = '1.8em'
      item.style.fontSize = '32px'
      item.style.fontWeight = '600'
       item.style.fontFamily = '简宋'
    })

  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.page_container {
  width: 100%;
  height: calc(100vh - 33px);
  padding: 5px 10px;
  background-color: rgba(237, 237, 240, 1);
  // background-color: #f00;
}

.page {
  height: 100%;
  overflow-y: auto;
  border: 5px solid transparent;
  background-clip: content-box;
  border-image: url('https://img.infox-med.com/webImg/infoPc/public/pdfPageShadow.png')
    9 9 repeat;
  background-color: #fff;
}
.page_content {
  padding: 40px 20px 30px;
  font-size: 14px;
  line-height: 2em;
  ::v-deep img {
    max-width: 100%;
    object-fit: cover;
    margin: 20px 0;
  }
}
</style>
