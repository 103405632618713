<template>
  <div class="ppt_view">
    <div class="view_top_options">
      <div class="ppt_types">
        <span class="hand" v-for="item in pptTypeList" :key="item.val"
              :class="{activateType: item.val === isEnglish}"
              @click="pptTypeChange(item.val)">{{item.label}}</span>

      </div>
      <span class="download_btn hand" @click="downloadPpt">下载PPT</span>
    </div>

    <div class="loading_box" v-show="createdLoading">
      <div class="loading_card">
        <div class="loading_icon" ref="loadingRef"></div>
        <p class="tac loading_tip">PPT生成中…</p>
      </div>
    </div>
    <iframe v-if="pptUrl" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${pptUrl}`"
            frameborder="0"></iframe>
  </div>
</template>

<script>
import { getPdfToPptByDocId } from '@/api/article.js'
import { initLottieByUrl, downloadFileByUrl } from '@/utils/index.js'
export default {
  props: {
    docId: Number,
    docInfo: Object
  },
  components: {},
  data () {
    return {
      createdLoading: false,
      inquiryNums: 0,
      waitTimeId: null,
      pptUrl: '',
      isEnglish: 1,
      urlZh: '',
      urlEn: '',
      pptTypeList: [
        {
          label: '英文版',
          val: 1
        },
        {
          label: '中文版',
          val: 0
        }
      ]
    }
  },
  methods: {
    async downloadPpt () {
      const fileName = this.isEnglish === 1 ? this.docInfo.docTitle : (this.docInfo.docTitleZh || this.docInfo.docTitle)
      await downloadFileByUrl(this.pptUrl, fileName)
      this.$message.success('已下载至本地，请查看')
    },
    pptTypeChange (type) {
      if (type === this.isEnglish) return
      const url = type === 1 ? this.urlEn : this.urlZh
      this.createdLoading = !url
      if (url) return this.pptUrl = url
      this.isEnglish = type
      this.pptUrl = ''
      this.poller()
    },
    async initLottie () {
      const element = this.$refs.loadingRef

      const lottieObj = await initLottieByUrl({
        el: element,
        url: 'https://img.infox-med.com/webImg/infoxmedV2/animation/contentLoadingAnimation.json'
      })
      lottieObj.play()
    },
    clearTime () {
      if (this.waitTimeId) {
        this.inquiryNums = 0
        this.createdLoading = false
        clearInterval(this.waitTimeId)
        this.waitTimeId = null
      }
    },
    async poller () {
      if (this.inquiryNums >= 20) {
        this.clearTime()
        this.$notify({
          title: '系统提示',
          message: '服务器繁忙，请稍后重试',
          duration: 0
        })
        return
      }
      const data = await this.getPdfToPptByDocId()
      if (data) {
        this.isEnglish === 1 ? this.urlEn = data : this.urlZh = data
        this.pptUrl = data
        this.createdLoading = false
        return
      }

      this.waitTimeId = setInterval(() => {
        this.inquiryNums++
        clearInterval(this.waitTimeId)
        this.poller()
      }, 40 * 1000)
    },
    async getPdfToPptByDocId () {
      const { data } = await getPdfToPptByDocId({
        documentId: this.docId,
        isEnglish: this.isEnglish
      })
      return data
    },
    async createdPpt () {
      if (this.createdLoading || this.pptUrl) return
      this.createdLoading = true
      this.poller()
    }
  },
  mounted () {
    this.initLottie()
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.ppt_view {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: calc(100% - 36px);
}

.loading_box {
  padding-top: 30px;
  .loading_card {
    width: 340px;
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
    background-color: #fff;

    .loading_icon {
      height: 150px;
    }
    .loading_tip {
      font-size: 14px;
      color: #666;
    }
  }
}

.view_top_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 5px;

  .download_btn {
    padding: 1px 10px;
    font-size: 12px;
    border-radius: 20px;
    color: #4b639f;
    transform: scale(0.9);
    border: 1px solid #4b639f;
    &:hover {
      background-color: #4b639f;
      color: #fff;
    }
  }
}
.ppt_types {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    padding: 1px 10px;
    font-size: 12px;
    border-radius: 20px;
    color: #4b639f;
    transform: scale(0.9);
    border: 1px solid #4b639f;
  }
  .activateType {
    background-color: #4b639f;
    color: #fff;
  }
}
</style>
