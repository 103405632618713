<template>
  <div class="page_container">
    <div class="page">
      <div class="page_content">
        <div class="title" v-html="info.titleZh">
        </div>
        <div class="section" v-for="(item,i) in info.sections" :key="i">
          <div class="section_title" v-html="item.headingZh"></div>
          <div class="text" v-html="item.textZh"></div>
        </div>
      </div>

      <div v-show="false" ref="pageContentRef">
        <div style="font-size: 36px;font-weight: 600;text-align: center;margin-bottom: 20px; line-height: 1.8em; font-family:简宋;"
             v-html="info.titleZh">
        </div>
        <div style="" v-for="(item,i) in info.sections" :key="i">
          <div style="font-size: 36px;margin: 20px 0 10px;font-weight: 600; line-height: 1.8em; font-family:简宋;"
               v-html="item.headingZh"></div>
          <div style="font-size: 28px; line-height: 1.8em; font-family:简宋;" v-html="item.textZh"></div>
        </div>
      </div>
    </div>

    <div class="export_page">

    </div>

  </div>
</template>

<script>
import system from '@/api/system.js'
import { downloadFileToUrl } from '@/utils/index.js'

export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    docInfo: Object,
    exportLoading: Boolean
  },
  components: {},
  data () {
    return {

    }
  },
  methods: {
    async export () {
      this.$emit('update:exportLoading', true)
      const el = this.$refs.pageContentRef
      const html = el.innerHTML
      const res = await system.proxy({
        url: 'http://172.20.60.11:5004/convert_html_to_pdf',
        body: {
          html
        }
      },
        {
          hideLoading: true
        }
      )
      await downloadFileToUrl({
        url: res.url,
        fileName: this.docInfo.docTitleZh + '.pdf'
      })
      this.$emit('update:exportLoading', false)
    }
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.page_container {
  // height: 100;
  width: 100%;
  height: calc(100vh - 33px);
  padding: 5px 10px;
  background-color: rgba(237, 237, 240, 1);
  // background-color: #f00;
}

.page {
  height: 100%;
  overflow-y: auto;
  border: 5px solid transparent;
  background-clip: content-box;
  border-image: url('https://img.infox-med.com/webImg/infoPc/public/pdfPageShadow.png')
    9 9 repeat;
  background-color: #fff;
}
.page_content {
  padding: 40px 20px 30px;
  font-size: 14px;
  line-height: 2em;
}
.title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.section {
  .section_title {
    font-size: 16px;
    margin: 20px 0 10px;
    font-weight: 600;
  }
}
</style>
